@font-face {
  font-family: 'marck_script_regular';    
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/marck-script.regular.ttf');
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/marck-script.regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat_bold';
  src: url("https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-bold.ttf");
  src: url("https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-bold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat_light';
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-light.ttf');
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat_semibold';
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-semibold.ttf');
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratregular';
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-regular.ttf');
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/montserrat-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* text-security-disc added to give a look of password on input type text, eot file type is added for IE browser while ttf use for other browsers */
@font-face {
  font-family: 'text-security-disc';
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/text-security-disc-compat.eot');
  src: url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/text-security-disc-compat.eot?#iefix') format('embedded-opentype'),
  url('https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/fonts/text-security-disc-compat.ttf') format('truetype');
  font-display: swap;
}