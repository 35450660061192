button.close,
.btn.close {
  color: #1f1f1f;
}

button.close:hover,
.btn.close:hover {
  color: #014353;
}

.modal-content {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid #666666;
}

.modal-header {
  display: block;
  background-color: #dfdfdf;
}

.modal-title {
  font-weight: bold;
}

.modal--lg .modal-dialog {
  max-width: 976px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal--lg .modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .modal--lg .modal-dialog {
    max-width: calc(100% - 16px);
    margin: 8px;
    height: calc(100vh - 16px);
  }

  .modal--lg .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .modal--lg .modal-body {
    flex: 1;
    overflow-y: scroll;
  }
}
