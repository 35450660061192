/*
    This style sheet will allow us to easily modify bootstrap.
    Note in order to override the important tag is required.
*/
.col-no-padding-sides {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.col-no-padding {
  padding: 0px 0px 0px 0px !important;
}

/* 
  Adding these due to https://github.com/twbs/bootstrap/issues/19402
  Found in https://connecture.atlassian.net/browse/SPA-14903
  Can remove if we ever downgrade bootstrap or add hrefs to our anchors
*/
a.Spa_Style_Link:not([href]) {
  color: var(--Spa_Style_Primary_Link);
  text-decoration: underline;
}

a.Spa_Style_Link.always-underline:not([href]) {
  text-decoration: underline;
}

a.Spa_Style_Link:not([href]):hover {
  text-decoration: underline;
}
