/* You can add global styles to this file, and also import other style files */
/************************* Begin Custom CSS Template *************************/
@import './app//styles/bootstrap-modify.css';
@import './app/styles/fonts.css';
@import './app/styles/modal.css';
/* CSS Overrides
***************************************************************/
:root {
  --focus-shadow-color: #959595;
  --input-height: 36px;
}

body {
  font-family: 'montserratregular', sans-serif;
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
  margin: 0;
}

.popover {
  font-family: 'montserratregular', sans-serif;
}

.can-click {
  cursor: pointer;
}

.no-underline,
.no-underline:hover,
.no-underline > a,
.no-underline > a:hover {
  /* using !important here as this is an override class and we sometimes target anchor with extra attributes making it harder to override without this */
  text-decoration: none !important;
}

a {
  color: var(--Spa_Style_Primary_Link);
}

a:hover {
  color: var(--Spa_Style_Primary_Link_Hover);
}

a,
a:not([href]) {
  text-decoration: underline;
}
a:hover,
a:not([href]):hover {
  text-decoration: underline;
}

.btn-reset {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

vnext-login .popover-body,
vnext-profile-popover .popover-body,
vnext-speak-with-agent .popover-body {
  padding: 12px;
}

input[type='text'],
input[type='email'],
input[type='password'],
.med-sup-dropdown-toggle {
  border-width: 1px;
  border-style: solid;
  border-color: var(--static-Spa_Style_Accent1);
  height: var(--input-height);
  padding: 5px 11px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(var(--static-Spa_Style_Gray2) 0%, var(--static-Spa_Style_Gray2) 0%, white 25%, white 100%);
}

input[type='text'],
input[type='email'],
input[type='password'],
.form-control,
.med-sup-dropdown-toggle {
  color: var(--static-Spa_Style_Black);
  font-size: var(--Spa_Style_Small_Font);
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
.monthwise-cost-list .btn.btn-link:focus,
.form-control:focus,
.can-click:focus,
.slider-knob:focus,
.dropdown-toggle:focus,
.dropdown-item:focus,
.toggle-option:focus,
.page-link:focus,
.tab:focus,
.Spa_Style_PrimaryButton:focus,
.Spa_Style_SecondaryButton:focus,
a:focus,
button:focus,
button.btn:focus,
[class*='DFSpa']:focus,
[tabindex='0']:focus,
vnext-enrollment-form input:focus {
  outline: 1px solid var(--static-Spa_Style_Primary);
  border-color: transparent;
  box-shadow: 0 0 5px var(--focus-shadow-color) !important;
}

input[type='checkbox']:focus,
input[type='radio']:focus {
  outline: 0;
  border-color: var(--static-Spa_Style_Primary);
  box-shadow: 0 0 5px var(--focus-shadow-color) !important;
}

i.fas:focus,
a[class*='-popover-cancel']:focus {
  border-radius: 50%;
}
.text-underline,
a.text-underline {
  text-decoration: underline;
}

.btn,
.toggle-option {
  min-height: var(--input-height) !important;
  line-height: 1.4 !important;
}

.btn {
  font-size: var(--Spa_Style_Regular_Font) !important;
}

/* 'Bootstrap' custom classes start*/
.c-mt-30 {
  margin-top: 30px !important;
}

.c-mb-30 {
  margin-bottom: 30px !important;
}
/* 'Bootstrap' custom classes end*/

.focus-text:focus,
vnext-link :focus {
  padding: 4px;
  margin: -4px;
  border-radius: 5px;
}

.focus-circle:focus {
  padding: 2px;
  margin: -2px;
  border-radius: 50%;
}

.has-error[ngbdropdown],
.has-error [ngbdropdowntoggle],
input[type='text'].has-error,
input[type='email'].has-error,
input[type='password'].has-error {
  border-color: var(--static-Spa_Style_Accent2);
}

input.form-control:disabled,
input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder {
  color: var(--static-Spa_Style_Gray4);
}

.input-asterisk,
.input-error-asterisk {
  font-family: montserrat_bold;
  margin-right: 5px;
}

.input-error-asterisk {
  color: var(--static-Spa_Style_Accent2);
}

.input-label-text {
  text-align: left;
}

.warn-color,
.warn-container {
  color: var(--static-Spa_Style_Warn_on_Blue);
  width: 100%;
}

.warn-container > .Spa_Style_Paragraph {
  color: var(--static-Spa_Style_Warn_on_Blue);
}

.warn-container > .fa-exclamation-triangle {
  margin-right: 5px;
  font-size: var(--Spa_Style_Small_Font);
  color: var(--static-Spa_Style_Warn_Darker);
}

.error-color,
.error-container {
  color: var(--static-Spa_Style_Accent2);
  width: 100%;
}

.error-container > .fa-exclamation-circle {
  margin-right: 5px;
}

strong {
  font-family: 'montserrat_bold', sans-serif;
}

.currency {
  color: var(--static-Spa_Style_Cost_Text);
}

.width-fit-content {
  width: fit-content;
}

/* Plan logo Styles */

.plan-logo {
  height: auto;
  width: auto;
}

.generic-logo-dimension {
  max-height: var(--input-height);
  max-width: 110px;
}

.logo-dimension {
  max-height: 50px;
  max-width: 150px;
}

/* Start Tooltip Styles */

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top .arrow::before {
  border-top-color: white;
  border-width: 0.5rem 0.5rem 0;
  top: -1px;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: white;
  border-width: 0 0.5rem 0.5rem;
  bottom: -1px;
}

.bs-tooltip-left .arrow::before {
  border-left-color: white;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  left: -1px;
}

.bs-tooltip-right .arrow::before {
  border-right-color: white;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  right: -1px;
}

.bs-tooltip-top {
  margin-bottom: 8px;
}

.bs-tooltip-bottom {
  margin-top: 8px;
}

.bs-tooltip-left {
  margin-right: 8px;
}

.bs-tooltip-right {
  margin-left: 8px;
}

.tooltip-inner {
  color: var(--static-Spa_Style_Primary_Darker) !important;
  background-color: white !important;
  min-width: 300px;
  max-width: 600px;
  box-shadow: 0px 0px 8px 3px var(--static-Spa_Style_Gray4);
  text-align: left;
  border: 1px solid var(--static-Spa_Style_Accent1);
  font-family: 'montserratregular', sans-serif;
  font-size: var(--Spa_Style_Small_Font);
  padding: 1px;
  border-radius: 6px;
}

.tooltip-inner h4,
.tooltip-inner h5 {
  font-weight: 600;
}

.tooltip-inner h4 {
  font-size: var(--Spa_Style_Regular_Font);
}

.tooltip-inner h5 {
  font-size: var(--Spa_Style_Small_Font);
}

.dropdown-toggle::after {
  display: none;
}
/* End Tooltip Styles */

/* Pagination Styles Start */

.page-item.disabled .page-link .btn {
  color: var(--static-Spa_Style_Accent1);
  background-color: var(--static-Spa_Style_Gray1);
}

.pagination .page-link {
  background-image: none !important;
  border: none !important;
  padding: 0px;
}

.page-item.active .page-link {
  background-color: transparent !important;
}

.page-item .btn {
  background-color: var(--static-Spa_Style_Gray2);
  border: 1px solid var(--static-Spa_Style_Accent1);
  font-family: montserratregular;
}

.page-item.active .btn {
  background-color: var(--static-Spa_Style_Toggle);
  color: var(--static-Spa_Style_Primary_Link);
  font-family: montserrat_bold;
}

/* End Pagination Styles */

/* Plan Details Styles */
.plan-details-accordion .card-header {
  border-bottom: none;
  padding: 0;
  border-radius: 0;
}

.plan-details-accordion .section-accordion-container .card-header {
  border-bottom: 1px solid var(--static-Spa_Style_Accent1);
  margin-bottom: 0;
}

.plan-details-accordion .sub-section-accordion-container .card-header {
  border-bottom: 1px solid var(--static-Spa_Style_Accent1);
  margin-bottom: 0;
}

.plan-details-accordion .card-body {
  padding: 0;
}

.ancillary-plan-section-details ul {
  padding-left: 14px;
}

.ancillary-plan-section-details li {
  font-size: var(--Spa_Style_Small_Font);
  padding: 6px 0;
}

.ancillary-plan-section-details ul,
.ancillary-plan-sub-section-details ul li,
.ancillary-plan-sub-section-details li {
  list-style-type: none;
}

.ancillary-plan-section-details ul li::before,
.ancillary-plan-sub-section-details ul li::before,
.ancillary-plan-sub-section-details li::before {
  content: '\2022';
  color: var(--static-Spa_Style_Accent1);
  font-size: var(--Spa_Style_Regular_Font);
  font-weight: bolder;
  margin-right: 0.5em;
}

.ancillary-plan-sub-section-details {
  padding: 5px 0;
  font-size: var(--Spa_Style_Regular_Font);
  font-family: montserratregular;
  color: var(--static-Spa_Style_Black);
}

.ancillary-plan-sub-section-details h2 {
  font-size: var(--Spa_Style_Larger_Font);
  font-family: montserrat_bold;
}

.compare-warning-tooltip {
  left: -14px !important;
  top: -5px !important;
}

.combo-plan-tooltip.tooltip .tooltip-inner,
.checkbox-tooltip.tooltip .tooltip-inner,
.snp-indicator-tooltip.tooltip .tooltip-inner {
  min-width: 350px;
  max-width: 450px;
  padding: 12px;
}

.ancillary-tooltip.tooltip .tooltip-inner {
  min-width: 100px;
  max-width: 150px;
}

.compare-warning-tooltip.tooltip .tooltip-inner {
  min-width: 200px;
  max-width: 200px;
  text-align: left;
  padding: 6px;
}

.plan-compare-main-section .plan-details-accordion .card {
  border: 0;
  border-radius: 0;
  padding-bottom: 2px;
}

.plan-compare-main-section .plan-details-accordion .card-body {
  padding: 1px 0px 0px;
  border: 1px solid var(--static-Spa_Style_Accent1);
}

.footnote-header b {
  font-family: 'montserrat_semibold';
}

.pharmacy-pagination .pagination-sm .page-link {
  padding: 0.25rem 0.45rem;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1440px;
  width: 100%;
}

@media (max-width: 1200px) {
  .compare-warning-tooltip.tooltip .tooltip-inner {
    min-width: 170px;
    max-width: 170px;
    padding: 5px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .dropdown-container .menu.dropdown-menu {
    top: 5% !important;
    left: 5% !important;
    transform: translate(0px, 67px) !important;
    width: 90% !important;
  }

  .plan-details-accordion .card,
  .plan-details-accordion .section-accordion-container .card-header {
    border: 0;
  }

  /* !important property is used here as the min-width is set by the main component vnext-zip-code-input */
  vnext-member-registration #demographic_input {
    min-width: 150px !important;
  }

  .star-ratings-tooltip {
    left: -30px !important;
    margin-right: 8px !important;
  }
  /* !important property is used here as the min-width is set by the tooltip library already in the .tooltip-inner class */
  .star-ratings-tooltip.tooltip .tooltip-inner {
    min-width: unset !important;
  }

  .combo-plan-tooltip.tooltip .tooltip-inner,
  .checkbox-tooltip.tooltip .tooltip-inner,
  .snp-indicator-tooltip.tooltip .tooltip-inner {
    min-width: 180px;
  }

  .logo-dimension {
    max-height: var(--input-height);
    max-width: 110px;
  }
}

.notice-banner {
  border-radius: 5px;
  border: 1px solid transparent;
  border-left-width: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 18px;
}

.notice-banner.warning {
  border-left-color: var(--static-Spa_Style_Accent4);
  background-color: var(--static-Spa_Style_Warn_Lighter);
}

.notice-banner.warning > .fa {
  color: var(--static-Spa_Style_Accent4);
  font-size: 26px;
  vertical-align: text-top;
  padding-right: 10px;
}

.notice-banner > .notice-messages {
  vertical-align: text-top;
  font-size: var(--Spa_Style_Small_Font);
}

.notice__title {
  color: var(--Spa_Style_Primary);
  font-size: 14px;
  font-family: 'montserrat_semibold', sans-serif;
  /* This is holdover from previous pattern library styles, but helpful to keep in comments for now -- the rem's will be helpful if/when we implement a "text size increase" feature like PC has had in the past */
  /* font-size: 1rem; */
}

.notice ul,
.notice ol {
  margin: 0 0 0 18px;
  padding: 0;
}

/* DrugsSearch Styles Start*/
.drug-search-list ngb-typeahead-window {
  right: 0;
  max-height: 241px;
  overflow-y: auto;
}

.drug-search-list .dropdown-item.active,
.drug-search-list .dropdown-item:active {
  color: white;
  text-decoration: none;
  background-color: var(--static-Spa_Style_Gray1);
}

.plan-details-benefit-section-header > p {
  margin-bottom: 0px;
}
.drug-search-list button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*  DrugsSearch Styles Ends */

.pharmacyMapInfo,
.pharmacy-map-usergroup-info,
.pharmacy-map-usergroup-helpfultools-info {
  transform: translate(-50%, -120%);
}

.pharmacyMapInfo .si-content-wrapper,
.pharmacy-map-usergroup-info .si-content-wrapper,
.pharmacy-map-usergroup-helpfultools-info .si-content-wrapper {
  padding: 0;
}

.pharmacyMapInfo .si-content {
  max-width: 220px !important; /*setting as important to override inline style*/
}

.pharmacyMapInfo .si-pointer-top,
.pharmacy-map-usergroup-info .si-pointer-top,
.pharmacy-map-usergroup-helpfultools-info .si-pointer-top {
  border-width: 10px !important; /*setting as important to override inline style*/
}

.pharmacyMapInfo .si-pointer-bg-to,
.pharmacy-map-usergroup-info .si-pointer-bg-top,
.pharmacy-map-usergroup-helpfultools-info .si-pointer-bg-top {
  top: -2px !important; /*setting as important to override inline style*/
}

.pharmacy-map-usergroup-info .si-content {
  max-width: 290px !important; /*setting as important to override inline style*/
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 30px;
}

.pharmacy-map-usergroup-helpfultools-info .si-content {
  max-width: 210px !important; /*setting as important to override inline style*/
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 30px;
}

.pharmacy-map-usergroup-info .si-close-button,
.pharmacy-map-usergroup-helpfultools-info .si-close-button {
  font-weight: 900;
  color: var(--static-Spa_Style_Black);
}

/*Styling for plan list accordion because bootstrap hates the idea of giving overrides to their components for some reason */
vnext-plan-filter > ngb-accordion > .card > .card-header,
vnext-plan-list-accordion > ngb-accordion > .card > .card-header {
  background-color: white;
  padding: 0px;
  border-right: none;
  border-left: none;
}

vnext-plan-filter > ngb-accordion > .card,
vnext-plan-list-accordion > ngb-accordion > .card {
  border-right: none;
  border-left: none;
  border-bottom: none;
  overflow: initial !important; /* Override ngb-accordion styling in order to show Focus styling box-shadow */
}
vnext-plan-filter > ngb-accordion > .card > .card-header > button,
vnext-plan-list-accordion > ngb-accordion > .card > .card-header > button {
  width: 100%;
  text-decoration: none;
  padding: 5px 0px;
}

vnext-plan-filter > ngb-accordion > .card > .card-header > button i,
vnext-plan-list-accordion > ngb-accordion > .card > .card-header > button i {
  line-height: 21px;
}

vnext-plan-filter > ngb-accordion > .card > .card-header > button:hover,
vnext-plan-list-accordion > ngb-accordion > .card > .card-header > button:hover {
  text-decoration: none;
}
vnext-plan-filter > ngb-accordion > .card > div > .card-body,
vnext-plan-list-accordion > ngb-accordion > .card > div > .card-body {
  padding: 0px 0px 0px 0px;
  margin: 5px 0;
}
/* styling for button tooltip in drugs page - overriding bootstrap css*/
.drug-error-message .tooltip-inner {
  min-width: auto;
}
.phase-names-tooltip .tooltip-inner {
  min-width: 150px;
  max-width: 200px;
}
/* Electronic Prescribing Styles Starts*/
.eprescription-logo .tooltip-inner {
  min-width: 180px;
  max-width: 220px;
  text-align: left;
  padding: 10px;
}
/* Electronic Prescribing Styles Ends*/

.gm-disabled {
  cursor: default !important;
}
.gm-control-active > img:nth-child(1) {
  display: none;
}
.gm-control-active > img:nth-child(2) {
  display: block;
}
.gm-control-active.gm-disabled > img:nth-child(1),
.gm-control-active.gm-disabled:hover > img:nth-child(1),
.gm-control-active.gm-disabled:active > img:nth-child(1) {
  display: block;
}
.gm-control-active.gm-disabled > img:nth-child(2),
.gm-control-active.gm-disabled:hover > img:nth-child(2),
.gm-control-active.gm-disabled:active > img:nth-child(2),
.gm-control-active.gm-disabled:active > img:nth-child(3) {
  display: none;
}

.tooltip .tooltip-inner {
  background-color: white;
  color: var(--static-Spa_Style_Black);
  min-width: 180px;
  max-width: 220px;
  padding: 10px;
}

.med-sup-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  min-width: 400px;
}

.nudge-drug-alternatives-tooltip .tooltip-inner {
  max-width: 600px;
  min-width: 400px;
}

.star-ratings-tooltip.tooltip .tooltip-inner {
  min-width: 600px;
  max-width: 600px;
}

.health-slider .tooltip-inner {
  min-width: 275px;
  max-width: 275px;
  margin-top: -5vh;
}

.plan-fees-tooltip {
  width: 60%;
  min-width: 280px;
  max-width: 450px;
}

.plan-fees-tooltip.tooltip .arrow {
  z-index: 1;
}

.plan-fees-tooltip.tooltip .tooltip-inner {
  min-width: unset;
  max-width: unset;
  padding: 18px;
  will-change: transform;
  transform: translate(35%, 0px);
}

.plan-fees-tooltip.stacked-content.tooltip .tooltip-inner,
.plan-fees-tooltip.inline-block-layout.tooltip .tooltip-inner {
  will-change: unset;
  transform: unset;
}

/* Frequently Asked Questions styles */
.faq-border-bottom {
  border-bottom: 1px solid var(--static-Spa_Style_Accent1);
}

/* Plan Sub Details & Plan Quote Benefit to override the overflow to show benefit categories (riders) dropdowns */
vnext-plan-sub-details .accordion > .card,
vnext-widget-plan-quote-benefit-details .accordion > .card {
  overflow: visible;
}

/* Total Costs Monthly Breakdown - Equation styles */

.monthwise-cost-list .accordion > .card {
  border: none;
  border-bottom: 1px solid var(--static-Spa_Style_Accent1);
}
.monthwise-cost-list .accordion > .card .card-header {
  padding: 0;
  border: none;
  background: transparent;
}
.monthwise-cost-list .btn.btn-link {
  width: 100%;
}
.monthwise-cost-list .card-body {
  padding-top: 0;
  padding-bottom: 0;
}
.monthwise-cost-list .collapsed .fa-chevron-up:before {
  content: '\f078';
}

.warn-list-message a {
  color: var(--static-Spa_Style_Link);
}

.warn-list-message a:hover {
  color: var(--static-Spa_Style_Link_Hover);
}

vnext-account-overview .dropdown-menu {
  min-height: 50px;
  max-height: 400px;
  width: 300px;
  overflow: auto;
}

vnext-member-registration .dropdown-menu {
  min-height: 50px;
  max-height: 300px;
  overflow: auto;
}

vnext-medsup-milliman-authorization .dropdown-menu {
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto;
}
vnext-status-button .tooltip .tooltip-inner {
  max-width: 100px;
  min-width: 120px;
  padding: 10px;
}
.continue-button {
  min-width: 120px;
}

/* Google Maps Styles Starts */

agm-map .gm-style-cc,
agm-map .gm-style a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gm-style-moc {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

/* Google Maps Styles Ends */

/* Override NgbDatepicker arrow color */

div.ngb-dp-arrow > button {
  color: var(--static-Spa_Style_Black);
}

#page-loader .loading-text {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  right: 0;
  text-align: center;
  transform: none;
}

.spinner-page-load-text {
  color: white;
  font-size: var(--Spa_Style_Large_Font);
  font-family: montserrat_bold;
  text-align: center;
  width: 90%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.plantype-text > p:first-child {
  display: inline-block;
  margin-left: 5px;
}

.hidden-off-screen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.action-button {
  box-shadow: none;
  border: 0;
  background: transparent;
  padding: 0;
}
.action-button:focus {
  border-radius: 5px;
  box-shadow: 0 0 5px var(--static-Spa_Style_Black);
}

.quote-login-wrapper input[type='text']:focus,
.quote-login-wrapper [tabindex='0']:focus,
.quote-login-wrapper a:focus,
.quote-login-wrapper button.btn:focus {
  box-shadow: 0 0 7px var(--static-Spa_Style_Gray1) !important;
}
.map-custom-search-control {
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

/* Member Registration Address Autocomplete */
.address-autocomplete-wrapper .dropdown-item.active,
.address-autocomplete-wrapper .dropdown-item:active {
  background-color: var(--static-Spa_Style_Gray1);
}

/* Plan filter items modal */
vnext-plan-filter-modal.component-host-scrollable {
  overflow-y: auto !important;
}

.readonly {
  pointer-events: none;
}

/* Speak With Agent feature SPA-8145*/
.speak-with-agent-popover {
  max-width: 100%;
}

.agent-popover {
  max-width: 320px;
}

.speak-with-agent-link-top,
.speak-with-agent-link-bottom {
  line-height: 16px;
}

/* Add permission feature*/
.add-permission-popover.popover,
.filter-permission-popover.popover {
  max-width: 1390px;
  position: relative !important;
  z-index: 1;
  transform: translate(0px, 0px) !important;
}

.add-permission-popover.popover .arrow {
  right: 10em;
}

.add-permission-popover .popover-body {
  padding: 18px;
}

.checkbox-dropdown .dropdown-menu {
  width: 250px;
  min-height: 50px;
  max-height: 330px;
  overflow: auto;
}

.clear-both {
  clear: both;
}

.login-popover {
  /* so that it fits fully on 320px smallest viewport size */
  min-width: 295px;
}

.hip-benefits-popover {
  max-width: 100%;
  min-width: 320px;
}

.template-standard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

vnext-enrollment-form h1 {
  font-size: var(--Spa_Style_Largest_Font);
  font-family: 'montserrat_light';
  color: var(--static-Spa_Style_Black);
}

.address-list-popover .popover-body,
.helpful-tools.address-list-popover {
  min-width: 350px !important;
}

@media (max-width: 575.98px) {
  .hip-benefits-popover {
    max-width: 80%;
  }

  vnext-enrollment-form h1 {
    font-size: 26px;
  }

  .plan-fees-tooltip.stacked-xs-content.tooltip .tooltip-inner,
  .plan-fees-tooltip.inline-block-xs-layout.tooltip .tooltip-inner {
    will-change: unset;
    transform: unset;
  }

  .dsnp-planlist-tooltip.tooltip {
    left: -45px !important;
  }

  .dsnp-planlist-tooltip.tooltip .arrow {
    left: calc(calc(50% - 0.4rem) + 45px) !important;
  }
}

.tooltip button.close,
.tooltip .btn.close {
  opacity: 1;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hip-benefits-popover {
    max-width: 80%;
  }

  vnext-enrollment-form h1 {
    font-size: 26px;
  }

  .plan-fees-tooltip.stacked-sm-content.tooltip .tooltip-inner,
  .plan-fees-tooltip.inline-block-sm-layout.tooltip .tooltip-inner {
    will-change: unset;
    transform: unset;
  }

  .accordion-item-tooltip.tooltip .tooltip-inner,
  .dsnp-planlist-tooltip.tooltip .tooltip-inner {
    min-width: 500px;
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .plan-fees-tooltip {
    min-width: 400px;
  }

  .plan-fees-tooltip.tooltip .tooltip-inner {
    will-change: unset;
    transform: unset;
  }

  .accordion-item-tooltip.tooltip .tooltip-inner,
  .dsnp-planlist-tooltip.tooltip .tooltip-inner {
    min-width: 500px;
    max-width: 500px;
  }

  /* We only want to turn these styles off when are not on mobile/tablet */
  a[href^='tel:'] {
    text-decoration: none;
    cursor: default;
    color: inherit;
  }
}

.voice-signature-modal .modal-dialog,
.authorization-code-signature-modal .modal-dialog {
  max-width: 800px;
}

.default-item-text-color {
  color: var(--static-Spa_Style_Gray4);
}

.accent1-static {
  color: var(--static-Spa_Style_Accent1);
}

vnext-enrollment-card-details .accordion .card {
  border-radius: 0 0 0.25rem 0.25rem;
}

@media (min-width: 992px) {
  vnext-zone-content-right.col-md-3.col-lg-3.offset-lg-1 {
    margin-left: 0;
  }
  /* View encapsulation disallows us to modify this directly in the component */
  vnext-page-not-found vnext-image-banner-container .header-image {
    background-position-y: -200px !important;
  }
}

@media (min-width: 1400px) {
  /* View encapsulation disallows us to modify this directly in the component */
  vnext-page-not-found vnext-image-banner-container .header-image {
    background-position-y: -300px !important;
  }
}

@media print {
  /* We never want to use position: sticky while printing, it causes print layout issues */
  .sticky {
    position: static !important;
  }
}

.member-card-div {
  container-type: inline-size;
  container-name: member-card-container;
}

.progress-card-div {
  display: grid;
}

@container member-card-container (min-width: 230px) {
  .progress-card-div {
    display: flex;
    justify-content: space-between;
  }

  .progress-card-status-code {
    flex: 50%;
  }
  .progress-card-status-date {
    flex: 50%;
  }
}

/* New lines of CSS above this import declaration only */
@import './app/styles/nextgen-forms-bridge.css';
/* No new lines of CSS below this import declaration */
